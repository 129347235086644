import {Box, Typography, useTheme} from '@mui/material'
import {DataGrid} from '@mui/x-data-grid'
import {tokens} from '../../theme'
import {mockDataTeam} from '../../data/data'
import { AdminPanelSettingsOutlined } from '@mui/icons-material'
import { LockOpenOutlined } from '@mui/icons-material'
import { SecurityOutlined } from '@mui/icons-material'
import Header from '../../components/Header'
const Team =() => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [{field: 'id', headerName: 'ID'},
    {field: 'name', headerName: 'Name', flex: 1,cellClassName: 'name-cell'},
    {field: 'age', headerName: 'Age',type: "number",cellClassName: 'name-cell',headerAlign: 'left',align:"left"},
    {field: 'phone', headerName: 'Phone Number', flex: 1},
    {field: 'email', headerName: 'Email', flex: 1},
    {field: 'access', headerName: 'Access Level', flex: 1,renderCell: (params) => {
      return (
        <Box
        width="60%"
        m="0 auto"
        p="5px"
        display="flex"
        justifyContent="center"
        backgroundColor={params.row.access === "admin" ? colors.greenAccent[400] : colors.redAccent[400]}
        borderRadius="4px"
        >
            {params.row.access==="admin"&&<AdminPanelSettingsOutlined/>}
            { params.row.access==="user"&&<LockOpenOutlined/>}
            {params.row.access==="manager"&&<SecurityOutlined/>}
            <Typography color={colors.grey[100]} sx={{ml:"5px"}}>{params.row.access}</Typography>
       </Box>
      )  
    }
         
},
    ] 
    return (
        <Box m="20px ">
            <Header title="TEAM" subtitle="This is your team"/>
            <Box m="40px 0 0 0" width="100%"height="75vh" sx={{
                "& .MuiDataGrid-root": {
                    border:"none"
                },
                "& .MuiDataGrid-cell": {
                    border:"none"
                },
                "& .name-cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],

                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
            }}>
              <DataGrid 
              rows={mockDataTeam}
              columns={columns}
              />
            </Box>
        </Box>
    )
}
export default Team
